@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: url("./../public/Gradient-bg.png") no-repeat center
    center/cover !important;
  color: #1f4e5f !important;
  overflow: auto;
  overflow-x: hidden;
  min-height: 100vh;
  background-attachment: fixed !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
